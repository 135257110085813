 const Menu = [
    {
        id: 1,
        category:"Gamer",
        image1:"assets/img/team/team_logo01.png",
        image2:"assets/img/team/match_vs.png",
        image3:"assets/img/team/team_logo02.png",
        title:"Forza <span>Horizon ii</span>",
        description:"Find tecology people for digital projects in public sector.There are many variations of passages of Lorem Ipsuavailable, but the majority have suffered.",
        price:"$125,00",
        btn:"Joining"
    },
    {
        id: 2,
        category:"Profesional",
        image1:"assets/img/team/team_logo03.png",
        image2:"assets/img/team/match_vs.png",
        image3:"assets/img/team/team_logo04.png",
        title:"Thanos <span>Sky ii</span>",
        description:"Find tecology people for digital projects in public sector.There are many variations of passages of Lorem Ipsu available, but the majority have suffered.",
        price:"$125,00",
        btn:"Joining"
    },
    {
        id: 3,
        category:"Adventure",
        image1:"assets/img/team/team_logo05.png",
        image2:"assets/img/team/match_vs.png",
        image3:"assets/img/team/team_logo06.png",
        title:'Call Of <span>Duty ii</span>',
        description:"Find tecology people for digital projects in public sector.There are many variations of passages of Lorem Ipsuavailable, but the majority have suffered.",
        price:"$125,00",
        btn:"Joining"
    }
    
] 
export default Menu;