import React from 'react'
import IndexFive from '../../components/index/indexfive/IndexFive'

const HomeFive = () => {
  return (

        <IndexFive/>
    
  )
}

export default HomeFive