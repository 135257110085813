import React from 'react'
import IndexOne from '../../components/index/indexone/IndexOne';

function HomeOne() {

  return (

  <IndexOne/>
  )
}

export default HomeOne
